@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&display=swap");

:root {
  --main-padding: 1.5rem;
  --secondary-padding: 1rem;
  --small-padding: 0.5rem;
  --mini-padding: 0.25rem;
  --small-gap: 0.5rem;
  --medium-gap: 1rem;
  --large-gap: 2rem;
  --full-width: 100%;
  --full-height: 100%;
  --transition-duration: 250ms;
  --transition-easing: ease-in-out;
  --font-family: "DM Sans", sans-serif;
  --max-width: 1440px;
  --font-color: #323035;
  --bg-color: #dedede;
  --border-color: #b5b2bc;
  --highlight-color: #49474e;
  --hover-bg-color: #1a191b;
  --hover-font-color: #dedede;
  --gray-color: #625f69;
  --light-gray-color: #7c7a85;
  --white-color: #ffffff;
  --light-white-color: #f7f7f7;
  --tw-color-red-200: #fecaca;
  --tw-color-orange-200: #fed7aa;
  --tw-color-yellow-200: #fef08a;
  --tw-color-green-200: #bbf7d0;
  --tw-color-blue-200: #bfdbfe;
  --tw-color-purple-200: #e9d5ff;
  --font-small: 0.675rem;
  --font-medium: 0.875rem;
  --font-large: 1rem;
  --font-xlarge: 1.25rem;
  --font-xxlarge: 2rem;
  --font-xxxlarge: 3rem;
  --margin-mini: 0.25rem;
  --margin-small: 1rem;
  --margin-medium: 2rem;
  --margin-large: 5rem;
}

/* @font-face {
  font-family: "Sterling";
  src: local("FTSterlingTrial-Regular"),
    url("./fonts/FTSterlingTrial-Regular.woff");
}*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: var(--full-width);
  max-width: var(--max-width);
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--gray-color);
  line-height: 140%;
  background-color: var(--white-color);
}

img {
  width: var(--full-width);
  max-width: calc((1440px - var(--main-padding)) / 2);
  overflow: hidden;
  height: auto;
}

.app {
  padding: var(--main-padding);
}

/* Header */
header.header-container {
  width: var(--full-width);
  height: var(--full-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid var(--border-color);
}

header.header-container h1 {
  color: var(--font-color);
  font-size: var(--font-xlarge);
  line-height: var(--font-xlarge);
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 500;
  padding-bottom: var(--main-padding);
  text-wrap: balance;
}

header.header-container h1 span.slash {
  color: var(--highlight-color);
}

header.header-container h1 span.subtitle {
  color: var(--light-gray-color);
}

/* Menu links */
.menu-links {
  display: flex;
  gap: var(--medium-gap);
  padding-bottom: var(--main-padding);
  transition: max-height 0.3s ease-in-out;
  background-color: var(--white-color);
}
.menu-links a {
  font-size: var(--font-medium);
  font-weight: 300;
  transition: all var(--transition-duration) var(--transition-easing);
}
.menu-links a:hover,
.hero-section a:hover {
  text-decoration: underline;
  color: var(--light-gray-color);
}

/* Menu toggle button */
.menu-toggle {
  display: none;
  font-size: var(--font-medium);
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--font-color);
  padding-bottom: var(--small-padding);
}

.menu-toggle:hover,
.menu-toggle:focus,
.menu-toggle:active,
.menu-toggle:visited {
  color: var(--hover-bg-color);
}

/* media queries mobile header */
@media (max-width: 768px) {
  .app {
    padding: var(--small-padding) var(--mini-padding);
  }

  header.header-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: var(--small-padding);
  }

  header.header-container h1 {
    padding: var(--mini-padding) 0;
  }

  .menu-toggle {
    display: block;
    margin-top: var(--mini-padding);
    width: 100%;
    z-index: 2;
  }

  .menu-links {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: var(--mini-padding) 0;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--white-color);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, visibility 0s 0.3s; /* Asegura que la visibilidad cambie tras la animación */
    visibility: hidden; /* Oculta completamente el menú cuando está cerrado */
    z-index: 999;
    gap: 0;
  }

  .menu-links a {
    font-size: var(--font-xlarge);
    padding: var(--margin-medium) 0;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    border-bottom: 1px solid var(--border-color);
    background-color: var(--white-color);
  }

  .menu-links a:first-child {
    border-top: 1px solid var(--border-color);
  }

  .menu-links.open {
    max-height: 550px;
    background-color: var(--white-color);
    visibility: visible;
  }
}

.hero-section {
  padding: var(--margin-large) 0;
  width: var(--full-width);
  height: var(--full-height);
  display: flex;
  flex-direction: column;
  gap: var(--main-padding);
  border-bottom: 1px solid var(--border-color);
}
.hero-section h2 {
  font-size: var(--font-xxxlarge);
  line-height: var(--font-xxxlarge);
  color: var(--font-color);
  font-weight: 300 !important;
  text-wrap: balance;
}

/* media queries mobile hero-section */
@media (max-width: 768px) {
  .hero-section {
    padding: var(--margin-medium) 0;
  }

  .hero-section h2 {
    font-size: var(--font-xxlarge);
    line-height: calc(var(--font-xxlarge) + var(--font-small));
  }
}

.hero-section a {
  text-decoration: underline;
  transition: all var(--transition-duration) var(--transition-easing);
}
.hero-section .emoji {
  font-size: var(--font-xxlarge);
  padding: var(--mini-padding);
}

/* Home Categories Container */
.home-container-categories {
  display: flex;
  flex-wrap: wrap;
  gap: var(--small-gap);
  justify-content: center;
  padding: var(--margin-medium) 0;
  width: var(--full-width);
}

/* Home Container Posts */
.home-container-post {
  display: flex;
  flex-wrap: wrap;
  gap: var(--medium-gap) var(--small-gap);
  min-height: 40dvh;
  transition: all var(--transition-duration) var(--transition-easing);
}

.loading {
  padding: var(--margin-small) 0;
  color: var(--font-color);
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

article.container-post {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 0;
  flex-basis: calc(33.333% - var(--small-gap));
  height: 185px;
  box-sizing: border-box;
  padding: var(--small-padding);
  transition: all var(--transition-duration) var(--transition-easing);
  border-radius: 0;
  border-top: var(--white-color);
  border-right: var(--white-color);
  border-bottom: var(--white-color);
  background-color: var(--white-color);
}

/* media queries mobile articles */
@media (max-width: 768px) {
  article.container-post {
    flex-basis: 100%;
    margin-bottom: var(--margin-mini);
    height: 185px;
    border-radius: var(--small-padding);
    background-color: var(--light-white-color);
  }
}

/* Categories  Slugs */
.idea-de-proyecto {
  border-left: 2px solid var(--tw-color-yellow-200);
}

.micro-blog {
  border-left: 2px solid var(--tw-color-orange-200);
}

.nota {
  border-left: 2px solid var(--tw-color-red-200);
}

.reflexion {
  border-left: 2px solid var(--tw-color-blue-200);
}

.historia {
  border-left: 2px solid var(--tw-color-green-200);
}

article.container-post:hover {
  cursor: pointer;
  opacity: 0.8;
  border-radius: var(--mini-padding);
}

article.container-post:hover.idea-de-proyecto {
  border: 2px solid var(--tw-color-yellow-200);
}

article.container-post:hover.micro-blog {
  border: 2px solid var(--tw-color-orange-200);
}

article.container-post:hover.nota {
  border: 2px solid var(--tw-color-red-200);
}

article.container-post:hover.reflexion {
  border: 2px solid var(--tw-color-blue-200);
}

article.container-post:hover.historia {
  border: 2px solid var(--tw-color-green-200);
}

.container-post span.date {
  font-size: var(--font-small);
  text-transform: uppercase;
  color: var(--light-gray-color);
}

.container-post h3 {
  font-size: var(--font-large);
  margin-top: -0.25em;
}

.container-post p {
  font-size: var(--font-medium);
  line-height: var(--font-xlarge);
  color: var(--font-color);
  text-wrap: pretty;
  word-break: break-word;
  margin: var(--margin-small) 0;
}

.container-post span.read-more,
.home-container-categories li {
  font-size: var(--font-medium);
  color: var(--hover-bg-color);
  padding: var(--mini-padding) var(--main-padding);
  border-radius: var(--main-padding);
  transition: all var(--transition-duration) var(--transition-easing);
  cursor: pointer;
  margin-bottom: var(--margin-small);
}

@media screen and (max-width: 768px) {
  ul.categories-list-container {
    flex-wrap: wrap;
    gap: var(--small-gap);
  }
  .categories-list-container li {
    flex-basis: calc(50% - var(--small-gap));
    text-align: center;
  }
  .categories-list-container li.all {
    border: 1px solid var(--hover-font-color);
  }
  .container-post span.read-more {
    padding: var(--mini-padding) var(--small-padding);
  }
}

.home-container-categories li:hover {
  opacity: 0.8; /* El elemento sobre el que se pasa el ratón mantiene opacidad 1 */
}

.container-post span.read-more.micro-blog,
.home-container-categories li.micro-blog,
.post-page-header.micro-blog {
  background-color: var(--tw-color-orange-200);
}

.container-post span.read-more.idea-de-proyecto,
.home-container-categories li.idea-de-proyecto,
.post-page-header.idea-de-proyecto {
  background-color: var(--tw-color-yellow-200);
}

.container-post span.read-more.nota,
.home-container-categories li.nota,
.post-page-header.nota {
  background-color: var(--tw-color-red-200);
}

.container-post span.read-more.reflexion,
.home-container-categories li.reflexion,
.post-page-header.reflexion {
  background-color: var(--tw-color-blue-200);
}

.container-post span.read-more.historia,
.home-container-categories li.historia,
.post-page-header.historia {
  background-color: var(--tw-color-green-200);
}

/* Case Container */
.case-container {
  padding: var(--secondary-padding) 0;
  border-bottom: 1px solid var(--hover-font-color);
  width: var(--full-width);
  height: var(--full-height);
  display: grid;
  grid-template-columns: 1fr 2fr; /* Una columna para el header, dos para los artículos */
  gap: var(--main-padding);
}

.case-container-header {
  grid-column: 1; /* Primera columna */
  align-self: stretch;
  gap: var(--main-padding);
  padding: 0 var(--secondary-padding) 0 0;
  text-align: left;
  border-right: 1px solid var(--hover-font-color);
}

.case-container-header h1 {
  color: var(--font-color);
  font-size: var(--font-xlarge);
  line-height: var(--font-xlarge);
  font-weight: 500;
  margin-bottom: var(--main-padding); /* Espacio entre el título y el párrafo */
}
.case-container p {
  color: var(--gray-color);
  margin-bottom: var(--small-padding);
}

.case-articles {
  grid-column: 2; /* Segunda columna */
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas para los artículos */
  gap: var(--main-padding);
}

.article-case-study {
  display: flex;
  flex-direction: column;
  gap: var(--secondary-padding);
  transition: transform var(--transition-duration) var(--transition-easing);
}

.article-body {
  display: flex;
  flex-direction: column;
  gap: var(--secondary-padding);
}

.article-img-container {
  width: var(--full-width);
  overflow: hidden;
  position: relative;
  padding-top: 133.33%; /* Aspect ratio 3:4 (4/3 * 100) */
}

.article-img-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--full-width);
  height: var(--full-height);
  object-fit: cover;
  transition: transform var(--transition-duration) var(--transition-easing);
}

.article-img-container:hover img {
  transform: scale(1.05);
  cursor: pointer;
}

.article-header h2,
.article-card-container h2 {
  font-size: var(--font-xlarge);
  font-weight: 500;
}

.article-content-container {
  font-size: var(--font-large);
  color: var(--light-gray-color);
}

.article-card-container {
  background-color: var(--light-white-color);
  padding: var(--margin-small);
  border-radius: var(--margin-mini);
  transition: all var(--transition-duration) var(--transition-easing);
}

.article-card-container:hover {
  transform: translateX(1px);
  background-color: var(--tw-color-yellow-200);
  cursor: pointer;
}

/* case study page */
.case-study-page,
.post-page {
  font-family: var(--font-family);
  color: var(--font-color);
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--main-padding) var(--mini-padding);
}

.case-study-page-header,
.post-page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: var(--full-height); /* Ocupa todo el alto del navegador */
  text-align: center;
  padding: var(--margin-large) 0;
}

.case-study-page-header {
  margin-bottom: var(--margin-large);
}

.post-page-header {
  margin-bottom: var(--margin-medium);
}

.case-study-page-header h1,
.post-page-header h1 {
  font-size: var(--font-xxxlarge); /* Tamaño de fuente ajustado */
  line-height: var(--font-xxxlarge);
  font-weight: bold;
  margin-bottom: var(--margin-small);
  text-transform: uppercase; /* Uppercase para todo el texto */
}

.case-study-page-subtitle,
.post-page-subtitle {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--small-gap);
  margin-bottom: var(
    --margin-medium
  ); /* Mayor separación con el mockup inicial */
  font-size: var(--font-medium); /* Fuente más pequeña */
  color: var(--gray-color);
  text-transform: uppercase; /* Uppercase para todo el texto */
}

.case-study-page-subtitle {
  margin-top: var(--margin-small);
}

.post-page-subtitle {
  margin-top: 0;
}

.case-study-page-category {
  padding: var(--small-padding);
  font-size: var(--font-small); /* Disminuir tamaño de fuente general */
}

.case-study-page-image {
  width: var(--full-width);
  height: auto;
  margin: var(--margin-medium) 0;
}

.case-study-page-body,
.post-page-body {
  display: flex;
  /*flex-wrap: wrap;*/
  gap: var(--medium-gap);
}

.case-study-page-content,
.post-page-content {
  flex: 4; /* Ocupa 4/5 del ancho */
  max-width: 75%;
}

.case-study-page-content {
  padding: var(--medium-gap) var(--medium-gap) var(--medium-gap) 0;
}

.post-page-content {
  padding: 0 0 var(--medium-gapp) var(--medium-gap);
}

.case-study-page-content-inner,
.post-page-content-inner {
  padding-right: var(--main-padding);
}

.case-study-page-content h2,
.post-page-content h2 {
  font-size: var(--font-xlarge); /* Disminuir tamaño de fuente general */
  line-height: var(--font-xxlarge); /* Alto de línea para balancear el texto */
  text-wrap: balance;
  margin-bottom: var(--margin-mini);
}

.case-study-page-content p,
.post-page-content p {
  font-size: var(--font-xlarge); /* Disminuir tamaño de fuente general */
  line-height: var(--font-xxlarge); /* Alto de línea para balancear el texto */
  text-wrap: pretty;
  word-break: break-word;
  margin-top: var(--margin-medium);
  margin-bottom: var(--margin-medium);
}

.post-page-content p.no-margin-top {
  margin-top: 0;
}

/* WP blocks */
.wp-block-image {
  margin-bottom: var(--margin-medium);
}

.wp-block-list,
.wp-block-list code,
.wp-block-list h1,
.wp-block-list h2,
.wp-block-list h3,
.wp-block-list h4,
.wp-block-list h5,
.wp-block-list h6 {
  margin: var(--margin-small) 0;
  width: var(--full-width);
}

ul.vertical-list {
  display: flex;
  flex-direction: column;
}

.wp-block-list li {
  background-color: var(--light-white-color);
  padding: var(--margin-small);
  border-radius: var(--margin-small);
  margin: var(--margin-mini);
}

.wp-block-code {
  text-overflow: clip;
  text-wrap: wrap;
  word-break: break-word;
  overflow-x: hidden;
  margin: var(--margin-small) 0;
  padding: var(--margin-small);
  border-radius: var(--margin-small);
  background-color: var(--font-color);
  color: var(--tw-color-yellow-200);
}

.wp-block-embed__wrapper iframe,
.wp-block-video video {
  width: var(--full-width);
  height: 480px;
}

/* case study page and post page sidebar */

.case-study-page-sidebar,
.post-page-sidebar {
  flex: 1; /* Ocupa 1/5 del ancho */
  position: sticky;
  top: var(--margin-medium);
  align-self: start;
  max-width: 25%;
}

.case-study-page-sidebar {
  padding: var(--margin-medium);
}

.post-page-sidebar {
  padding: var(--small-gap) 0;
}

.case-study-page-sidebar h3,
.post-page-sidebar h3 {
  font-size: var(--font-xlarge); /* Tamaño de fuente ajustado */
  margin-bottom: var(--margin-small);
  text-transform: uppercase; /* Uppercase para todo el texto */
  font-weight: normal; /* No tan bold */
}

/* categories and tags lists */
.case-study-page-tech,
.post-page-tech {
  margin-bottom: var(--margin-medium);
}

.case-study-page-tech ul,
.post-page-tech ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--mini-padding);
}

.case-study-page-tech li,
.post-page-tech li {
  padding: var(--mini-padding) 0;
  font-size: var(--font-medium); /* Tamaño de fuente ajustado */
  border-bottom: 1px solid var(--font-color);
}

@media (max-width: 768px) {
  .case-study-page-tech ul,
  .post-page-tech ul {
    flex-direction: row;
    flex-wrap: wrap;
    margin: var(--margin-mini) 0 var(--margin-mini);
  }

  .case-study-page-tech li,
  .post-page-tech li {
    padding: var(--mini-padding);
    border-radius: var(--mini-padding);
    border: 1px solid var(--font-color);
  }
}

.case-study-page-link {
  font-size: var(--font-medium); /* Tamaño de fuente ajustado */
  font-weight: 600;
  color: var(--font-color); /* Texto negro */
  border: 1px solid var(--font-color); /* Borde negro */
  padding: var(--mini-padding) var(--main-padding); /* Menor tamaño */
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase; /* Uppercase para todo el texto */
  border-radius: 1rem; /* Borde redondeado */
  transition: all 150ms var(--transition-easing);
}

/* case study page mockups */
.case-study-page-link:hover {
  color: var(--hover-font-color);
  background-color: var(--hover-bg-color);
  zoom: 1.01;
}

.case-study-page-mockups {
  margin: var(--margin-medium) 0;
}

.case-study-page-mockup-desktop {
  width: var(--full-width);
  height: auto;
  margin-bottom: var(--margin-medium);
}

.case-study-page-mockup-mobile-container {
  display: flex;
  flex-direction: column; /* Disposición en columna */
  gap: var(--large-gap);
  align-items: center; /* Centra los elementos en el eje horizontal */
}

.case-study-page-mockup-mobile-container .case-study-page-mockup-mobile {
  width: var(--full-width); /* Ancho completo para mantener la consistencia */
  max-width: 235px;
  height: auto;
}

.case-study-page-mockup-mobile-container
  .case-study-page-mockup-mobile:first-child {
  margin-right: auto; /* Centra la primera imagen */
  align-self: flex-start; /* Alinea la primera imagen a la izquierda */
}

.case-study-page-mockup-mobile-container
  .case-study-page-mockup-mobile:nth-child(2) {
  margin: 0 auto;
  align-self: center;
}

.case-study-page-mockup-mobile-container
  .case-study-page-mockup-mobile:last-child,
.case-study-page-video-mobile {
  margin-left: auto; /* Empuja la última imagen a la derecha */
  margin-right: 0; /* Centra la última imagen */
  align-self: flex-end; /* Alinea la última imagen a la derecha */
}

.case-study-page-mockup-mobile-container img {
  width: var(--full-width); /* Ancho completo para mantener la consistencia */
  object-fit: contain;
  height: auto;
}

.case-study-page-video {
  margin: var(--margin-medium) 0;
}

/* media queries mobile case-studies list */
@media (max-width: 768px) {
  .case-container {
    display: grid;
    grid-template-columns: 1fr; /* Una columna para dispositivos móviles */
    grid-template-rows: auto 1fr; /* Encabezado fijo y artículos con scroll vertical */
    height: var(
      --full-height
    ); /* Ajustar la altura del contenedor al 100% del viewport height */
    overflow: hidden; /* Ocultar el desbordamiento horizontal */
    gap: var(--small-gap);
  }

  .case-container-header {
    grid-column: 1;
    grid-row: 1;
    /*position: sticky;
    top: 0;*/
    padding: var(--small-padding);
    z-index: 1;
    border: none;
  }

  .case-articles {
    grid-column: 1;
    grid-row: 2;
    display: flex;
    flex-direction: column; /* Mostrar los artículos como una columna */
    overflow-y: auto; /* Habilitar scroll vertical */
    padding: var(
      --small-padding
    ); /* Añadir relleno para evitar que los artículos se peguen a los bordes */
    gap: 0;
  }

  .case-articles::-webkit-scrollbar {
    display: none; /* Ocultar la barra de scroll */
  }

  .article-case-study {
    padding: var(--medium-gap) 0; /* Espacio entre artículos */
    width: var(--full-width);
    height: auto;
    border-bottom: 1px solid var(--hover-font-color);
  }
}

p,
a,
a:visited,
a:focus {
  /*text-wrap: balance;*/
  text-decoration: none;
  color: var(--font-color);
}

ul {
  list-style: none;
  display: flex;
  gap: 0.5dvw;
}

/* media queries case-study page and post page */
@media (max-width: 768px) {
  .case-study-page,
  .post-page {
    padding: var(--main-padding) var(--mini-padding);
  }

  .case-study-page-header,
  .post-page-header {
    height: auto; /* Ocupa todo el alto del navegador */
  }

  .case-study-page-header {
    margin-bottom: var(--margin-medium);
  }

  .post-page-header {
    margin-bottom: var(--margin-small);
  }

  .case-study-page-header h1,
  .post-page-header h1 {
    font-size: var(--font-xxlarge); /* Tamaño de fuente reducido */
    line-height: var(--font-xxlarge);
  }

  .case-study-page-category,
  .post-page-category {
    font-size: var(--font-small); /* Disminuir tamaño de fuente general */
  }

  .case-study-page-subtitle,
  .post-page-subtitle {
    gap: var(--small-gap);
    margin-bottom: var(--margin-small);
    font-size: var(--font-medium); /* Fuente más pequeña */
    line-height: var(--font-xlarge); /* Alto de línea para balancear el texto */
  }

  .case-study-page-body,
  .post-page-body {
    flex-direction: column; /* Dirección en columna */
    gap: var(--medium-gap);
    overflow-x: hidden;
  }

  .case-study-page-content,
  .post-page-content {
    flex: none; /* No crece ni se encoge */
    padding: var(--main-padding) 0; /* Simplificar padding */
    width: var(--full-width);
    max-width: var(--full-width);
  }

  /* Clases WP nativas */
  .case-study-page-content ul.wp-block-list,
  .post-page-content ul.wp-block-list {
    flex-direction: column;
    gap: var(--medium-gap);
  }

  .wp-block-list code,
  .wp-block-code {
    text-overflow: clip;
    text-wrap: wrap;
    overflow-x: hidden;
    margin: var(--margin-small) 0;
  }

  .wp-block-embed.is-type-video {
    position: relative;
    width: var(--full-width);
    padding-bottom: 56.25%; /* Relación de aspecto 16:9 */
    height: 0;
    overflow: hidden;
  }

  .wp-block-embed__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--full-width);
    height: var(--full-height);
  }

  .wp-block-embed__wrapper iframe {
    width: var(--full-width);
    height: 100%;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .case-study-page-sidebar,
  .post-page-sidebar {
    flex: none; /* No crece ni se encoge */
    position: relative; /* No sticky */
    max-width: var(--full-width);
  }

  .case-study-page-sidebar {
    padding: var(--main-padding) 0; /* Simplificar padding */
  }

  .post-page-sidebar {
    padding: 0;
  }

  .case-study-page-content-inner,
  .post-page-content-inner {
    padding-right: 0;
  }

  .case-study-page-mockup-mobile-container {
    gap: var(--medium-gap); /* Reducir espacio entre elementos */
  }

  .case-study-page-mockup-mobile-container
    .case-study-page-mockup-mobile:first-child,
  .case-study-page-mockup-mobile-container
    .case-study-page-mockup-mobile:nth-child(2),
  .case-study-page-mockup-mobile-container
    .case-study-page-mockup-mobile:last-child,
  .case-study-page-mockup-mobile:last-child,
  .case-study-page-video-mobile {
    align-self: center; /* Centrar todos los elementos */
    margin-left: auto;
    margin-right: auto;
  }

  .case-study-page-mockup-mobile-container img,
  .case-study-page-mockup-mobile-container video {
    max-width: var(--full-width);
  }

  .case-study-page-video video {
    width: var(--full-width); /* Hacer videos responsivos */
    height: auto;
  }
}

/* Archive */
.archive-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: var(--small-gap);
  border-bottom: 1px solid var(--hover-font-color);
  position: relative;
  cursor: pointer;
  overflow: hidden; /* Evita que el contenido se salga del contenedor */
  transition: height 0.3s var(--transition-easing);
  height: 90px; /* Altura inicial del contenedor */
}

.archive-item:first-child {
  border-top: none;
}

.archive-item:hover {
  height: auto;
  aspect-ratio: 176 / 83;
  background-color: var(--hover-bg-color);
  color: var(--hover-font-color);
  align-items: flex-start;
}

@media (max-width: 768px) {
  .archive-item {
    height: 70px; /* Altura inicial recomendada para móviles */
  }

  .archive-item:hover {
    aspect-ratio: 123 / 58;
    height: auto;
  }
}

.archive-item:hover .archive-text {
  color: var(--hover-font-color);
  background-color: var(--hover-bg-color);
  opacity: 1;
  mix-blend-mode: hard-light;
}

.img-one {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto; /* Ajusta la altura automáticamente según la relación de aspecto */
  opacity: 0;
  transition: opacity var(--transition-duration) var(--transition-easing);
  z-index: 1; /* La imagen estará detrás del texto */
  max-width: 1920px;
  object-fit: cover;
}

.archive-item:hover .img-one {
  opacity: 1;
}

.archive-text {
  font-size: var(--font-xlarge);
  font-weight: 500;
  width: 100%;
  padding: var(--secondary-padding) 0;
  z-index: 2; /* Asegura que el texto esté sobre la imagen */
  transition: all var(--transition-duration) var(--transition-easing);
  height: auto;
}

.archive-number,
.archive-year {
  vertical-align: super;
  margin-right: var(--margin-mini);
}

.archive-year {
  float: right;
}

.archive-year,
.archive-number,
.archive-category,
.archive-tech {
  font-size: var(--font-small);
  font-weight: 200;
  padding: 0 var(--mini-padding);
}
